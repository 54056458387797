import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=0b3fce17"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.30_babel-core@7.0.0-bridge.0_@babel+core@7.24.7__cac_xtky6fgfftp44o5h5yh65favje/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports